<template>
  <v-row
    class="match-height mt-4"
  >
    <v-col
      cols="12"
      md="3"
    >
      <statistics-card
        :name="'Total Customers'"
        :tooltip-text="'All customers registered in the system.'"
        :value="CustomerCount"
        :is-loading="isCustomerCountLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <statistics-card
        :name="'Active Customers'"
        :tooltip-text="'Customers with internet right now.'"
        :value="activePlanCustomers"
        :is-loading="isActivePlanCustomersLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <statistics-card
        :name="'Expired Customers'"
        :tooltip-text="'Customers whose internet plan has ended, including those exempted from disconnection and still have internet access.'"
        :value="expiredPlanCustomers"
        :is-loading="isExpiredPlanCustomersLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <statistics-card
        :name="'New Customers'"
        :tooltip-text="'Customers added this month.'"
        :value="newCustomers"
        :is-loading="isNewCustomers"
      />
    </v-col>
    <v-col cols="12">
      <active-customers-graph
        :title="'Active Customers '"
        :is-active-customers-loading="isActiveCustomersGraphData"
        :active-customers-graph-data="activeCustomersGraphData"
        @time-period-change="onActiveCustomersSelectedTimePeriodChange"
      />
    </v-col>
    <v-col cols="12">
      <acquisition-graph
        :title="'New Customer Acquisition '"
        :is-acquisition-loading="isCustomerAcquisitionGraphData"
        :acquisition-graph-data="acquisitionGraphData"
        @time-period-change="onCustomerAcquisitionSelectedTimePeriodChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import StatisticsCard from './components/StatisticsCard.vue'
import AcquisitionGraph from './components/AcquisitionGraph.vue'
import ActiveCustomersGraph from './components/ActiveCustomersGraph.vue'
import calculateBarWidth from '@/mixins/calculateBarWidth'

export default {
  components: { StatisticsCard, AcquisitionGraph, ActiveCustomersGraph },
  mixins: [calculateBarWidth],
  props: {
    stationId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isCustomerCountLoading: true,
    isActivePlanCustomersLoading: true,
    isExpiredPlanCustomersLoading: true,
    isNewCustomers: true,
    isCustomerAcquisitionGraphData: true,
    isPerStationRevenueBarGraphLoading: true,
    isActiveCustomersGraphData: true,
    CustomerCount: 0,
    activePlanCustomers: 0,
    expiredPlanCustomers: 0,
    newCustomers: 0,
    acquisitionGraphData: {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Revenue (Ksh)',
        data: [],
      }],
    },
    activeCustomersGraphData: {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Revenue (Ksh)',
        data: [],
      }],
    },
    perStationRevenueBarGraphData: {
      chart: {
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Revenue (Ksh)',
        data: [],
      }],
    },
    activeCustomersSelectedTimePeriod: 'daily',
    customerAcquisitionSelectedTimePeriod: 'monthly',
  }),
  mounted() {
    this.getCustomerCount()
    this.getActivePlanCustomers()
    this.getExpiredPlanCustomers()
    this.getNewCustomers()
    this.getCustomerAcquisitionGraphData()
    this.getActiveCustomersGraphData()
  },
  methods: {
    getCustomerCount() {
      this.isCustomerCountLoading = true
      axios
        .get(`analytics/customers/customer-count?stationId=${this.stationId}`)
        .then(response => {
          this.CustomerCount = response.data
          this.isCustomerCountLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isCustomerCountLoading = false
        })
    },
    getActivePlanCustomers() {
      this.isActivePlanCustomersLoading = true
      axios
        .get(`analytics/customers/customers-with-active-internet-plan-count?stationId=${this.stationId}`)
        .then(response => {
          this.activePlanCustomers = response.data
          this.isActivePlanCustomersLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isActivePlanCustomersLoading = false
        })
    },
    getExpiredPlanCustomers() {
      this.isExpiredPlanCustomersLoading = true
      axios
        .get(`analytics/customers/customers-with-expired-internet-plan-count?stationId=${this.stationId}`)
        .then(response => {
          this.expiredPlanCustomers = response.data
          this.isExpiredPlanCustomersLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isExpiredPlanCustomersLoading = false
        })
    },
    getNewCustomers() {
      this.isNewCustomers = true
      axios
        .get(`analytics/customers/new-customers-count?stationId=${this.stationId}`)
        .then(response => {
          this.newCustomers = response.data
          this.isNewCustomers = false
        })
        .catch(error => {
          console.log(error)
          this.isNewCustomers = false
        })
    },
    getCustomerAcquisitionGraphData() {
      this.isCustomerAcquisitionGraphData = true
      axios
        .get(`analytics/customers/time-series-new-customers?timePeriod=${this.customerAcquisitionSelectedTimePeriod}&stationId=${this.stationId}`)
        .then(response => {
          const newData = response.data.data
          const chartLabels = []
          const chartValues = []

          newData.forEach(element => {
            chartValues.push(element.value)
            chartLabels.push(element.period)
          })

          this.acquisitionGraphData = {
            ...this.acquisitionGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: [{
                name: 'New Customers',
                data: chartValues,
              }],
              yaxis: {
                title: {
                  text: 'New Customers',
                },
              },
              tooltip: {
                y: {
                  formatter(value) {
                    return parseInt(value, 10)
                  },
                },
              },
            },
          }
          this.isCustomerAcquisitionGraphData = false
        })
        .catch(error => {
          this.isCustomerAcquisitionGraphData = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
    getActiveCustomersGraphData() {
      this.isActiveCustomersGraphData = true
      axios
        .get(`analytics/customers/time-series-active-customers?timePeriod=${this.activeCustomersSelectedTimePeriod}&stationId=${this.stationId}`)
        .then(response => {
          const newData = response.data.data
          const chartLabels = []
          const chartValues = []

          newData.forEach(element => {
            chartValues.push(element.value)
            chartLabels.push(element.period)
          })

          this.activeCustomersGraphData = {
            ...this.activeCustomersGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: [{
                name: 'Active Customers',
                data: chartValues,
              }],
              yaxis: {
                title: {
                  text: 'Active Customers',
                },
              },
              tooltip: {
                y: {
                  formatter(value) {
                    return parseInt(value, 10)
                  },
                },
              },
            },
          }
          this.isActiveCustomersGraphData = false
        })
        .catch(error => {
          this.isActiveCustomersGraphData = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
    onCustomerAcquisitionSelectedTimePeriodChange(timePeriod) {
      this.customerAcquisitionSelectedTimePeriod = timePeriod
      this.getCustomerAcquisitionGraphData()
    },
    onActiveCustomersSelectedTimePeriodChange(timePeriod) {
      this.activeCustomersSelectedTimePeriod = timePeriod
      this.getActiveCustomersGraphData()
    },
  },
}
</script>
