<template>
  <v-card min-height="475">
    <v-card-title>
      <v-col
        cols="12"
        md="10"
      >
        <span class="text-capitalize">{{ selectedTimePeriod }} Active Customers</span>
        <p class="body-2 font-weight-thin mt-1">
          Customers with an active internet connection in the selected time period
        </p>
      </v-col>
      <v-col
        cols="10"
        md="2"
      >
        <v-select
          v-model="selectedTimePeriod"
          :items="timePeriod"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="handleTimePeriodChange"
        ></v-select>
      </v-col>
    </v-card-title>

    <div
      v-if="isActiveCustomersLoading"
      class="d-flex justify-center align-center"
      style="height: 100%;"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <div
      v-else
      id="chart"
    >
      <apexchart
        ref="chart"
        type="area"
        height="400"
        :options="activeCustomersGraphData"
        :series="activeCustomersGraphData.series"
      ></apexchart>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isActiveCustomersLoading: {
      type: Boolean,
      default: true,
    },
    activeCustomersGraphData: {
      type: Object,
      default: () => ({
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
        },
        series: [{
          name: 'Customers',
          data: [],
        }],
      }),
    },
  },
  data() {
    return {
      selectedTimePeriod: 'daily',
      timePeriod: [
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Monthly', value: 'monthly' },
        { text: 'Yearly', value: 'yearly' },
      ],
    }
  },
  methods: {
    handleTimePeriodChange(year) {
      this.$emit('time-period-change', year)
    },
  },

}
</script>
